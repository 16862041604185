@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,400italic");
@import './core/colors';

html {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}

body {
  color: $body-color;
  background-color: $background-color;
}

a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

code {
  color: #ffffff;
  background-color: $link-color;
  padding: 4px 4px;
  border-radius: 4px;
  font-size: 14px;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-main {
  background-color: $main-background-color;
}

