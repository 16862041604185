@import './core/colors';

footer {
  padding: 70px 0;
  color: $off-white;
  text-align: center;

  .link {
    color: $off-white;
    font-size: 35px;
    padding-left: 0;

    &:focus, &:hover {
      color: $off-white;
      text-decoration: none;
    }
  }
}
