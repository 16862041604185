@import 'colors';

.hero {
  background-color: $hero-background-color;
  color: $gray;
  font-weight: 300;
  text-align: center;
  h1 {
    font-weight: 400;
    font-size: 4em;
  }
}
