$green: #8FD1B0;
$other-green: #91D0B1;
$cyan: #33e0ff;
$blue: #92B3DD;
$blue2: #80D3D2;
$gray: #777;
$off-white: #FCF9F4;
$yellow: #FED275;
$purple: #C0A7DE;
$salmon: #cc7a6f;

$code-color: #333;
$code-background: rgba(0,0,0,0.06);

// tomorrow
// $markdown-code-color: #4d4d4c;
// $markdown-code-background: #ffffff;

$markdown-code-color: #4d4d4c;
$markdown-code-background: $off-white;

$brand-text-color1: #c89389;
$brand-text-color2: #edb1a6;

// $brand-color1: #f0f2f5;
$brand-color1: #efeee9;
$brand-color2: #dad9da;
$brand-color3: #f8d9c8;
$brand-color4: #cca99e;
$brand-color5: #ec9f7f;
$brand-color6: #667c30;
$brand-color7: #e0e3d3;
$brand-color8: #efeee9; // - grayish white

$body-color: #636472;
// $brand-color: #FBD4D4;
// $brand-color: #F6C8CC;
// $brand-color: #E9A0AC;
$brand-color: $brand-color6;
// $background-color: #4D4E59;
$background-color: $brand-color6;
$main-background-color: #ffffff;
// $main-background-color: #FFFCF8;

$hero-background-color: $brand-color8;

$link-color: $brand-color6;
$link-hover-color: #777;

$nav-highlight-color: $yellow;

$nav-background-color: #f8f8f8;
$nav-brand-color: $brand-color;
$nav-highlight-color: $yellow;
